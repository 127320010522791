export default {
    RegionHidden: [
        'HomeCountry', 'HomeBattleCountry', 'HomeDealCountry',, 'HomeBoardCountry'
    ],
    AreaHidden: [
        'HomeCountry', 'HomeRegion', 'HomeBattleCountry', 'HomeBattleRegion', 'HomeDealCountry', 'HomeDealRegion', 'HomeBoardCountry', 'HomeBoardRegion'
    ],
    storeHidden: [
        'HomeCountry', 'HomeRegion', 'HomeArea', 'HomeBattleCountry', 'HomeBattleRegion', 'HomeBattleArea', 'HomeDealCountry', 'HomeDealRegion', 'HomeDealArea', 'HomeBoardCountry', 'HomeBoardRegion', 'HomeBoardArea'
    ]
}