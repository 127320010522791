import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@//layout/index'
import Dashboard from '@/layout/dashboard'

Vue.use(VueRouter)

const routes = [
  {
    path:"/login",
    name:"Login",
    component:()=>import('@/views/Home/login'),
    meta:{title:"登录"}
  },
  {
    path:"/user",
    name:"User",
    component:()=>import('@/views/Home/user'),
    meta:{title:"个人中心"}
  },
  {
    path: '/',
    component:Dashboard,
    redirect:"/login",
    children:[
      {
        path:"aion/country",
        name:"HomeBoardCountry",
        component:()=>import('@/views/Aion/country'),
        meta:{title:"接待全国看板"}
      },
      {
        path:"aion/region",
        name:"HomeBoardRegion",
        component:()=>import('@/views/Aion/region'),
        meta:{title:"接待大区看板"}
      },
      {
        path:"aion/area",
        name:"HomeBoardArea",
        component:()=>import('@/views/Aion/area'),
        meta:{title:"接待小区看板"}
      },
      {
        path:"aion/store",
        name:"HomeBoardStore",
        component:()=>import('@/views/Aion/store'),
        meta:{title:"接待经销商看板"}
      }
    ]
  },
  {
    path: '/',
    component:Dashboard,
    redirect:"/login",
    children:[
      {
        path:"dashboard/country",
        name:"HomeBoardCountry",
        component:()=>import('@/views/Dashboard/country'),
        meta:{title:"接待全国看板"}
      },
      {
        path:"dashboard/region",
        name:"HomeBoardRegion",
        component:()=>import('@/views/Dashboard/region'),
        meta:{title:"接待大区看板"}
      },
      {
        path:"dashboard/area",
        name:"HomeBoardArea",
        component:()=>import('@/views/Dashboard/area'),
        meta:{title:"接待小区看板"}
      },
      {
        path:"dashboard/store",
        name:"HomeBoardStore",
        component:()=>import('@/views/Dashboard/store'),
        meta:{title:"接待经销商看板"}
      }
    ]
  },
  {
    path: '/',
    component:Layout,
    redirect:"/login",
    children:[
      {
        path:"/country",
        name:"HomeCountry",
        component:()=>import('@/views/Home/index'),
        meta:{title:"全国总览看板"}
      },
      {
        path:"/region",
        name:"HomeRegion",
        component:()=>import('@/views/Home/index'),
        meta:{title:"大区总览看板"}
      },
      {
        path:"/area",
        name:"HomeArea",
        component:()=>import('@/views/Home/index'),
        meta:{title:"小区总览看板"}
      },
      {
        path:"/company",
        name:"HomeCompany",
        component:()=>import('@/views/Home/index'),
        meta:{title:"经销商总览看板"}
      },
      {
        path:"battle",
        name:"HomeBattle",
        component:()=>import('@/views/Battle/index'),
        meta:{title:"全国战力看板"}
      },
      {
        path:"battle/country",
        name:"HomeBattleCountry",
        component:()=>import('@/views/Battle/country'),
    meta:{title:"全国战力看板"}
      },
      {
        path:"battle/region",
        name:"HomeBattleRegion",
        component:()=>import('@/views/Battle/region'),
        meta:{title:"大区战力看板"}
      },
      {
        path:"battle/area",
        name:"HomeBattleArea",
        component:()=>import('@/views/Battle/area'),
        meta:{title:"小区战力看板"}
      },
      {
        path:"battle/store",
        name:"HomeBattleStore",
        component:()=>import('@/views/Battle/store'),
        meta:{title:"经销商战力看板"}
      },
      {
        path:"deal",
        name:"HomeDeal",
        component:()=>import('@/views/Deal/index')
      },
      {
        path:"deal/country",
        name:"HomeDealCountry",
        component:()=>import('@/views/Deal/country'),
        meta:{title:"客情全国看板"}
      },
      {
        path:"deal/region",
        name:"HomeDealRegion",
        component:()=>import('@/views/Deal/region'),
        meta:{title:"客情大区看板"}
      },
      {
        path:"deal/area",
        name:"HomeDealArea",
        component:()=>import('@/views/Deal/area'),
        meta:{title:"客情小区看板"}
      },
      {
        path:"deal/store",
        name:"HomeDealStore",
        component:()=>import('@/views/Deal/store'),
        meta:{title:"客情经销商看板"}
      },
      {
        path:"board",
        name:"HomeBoard",
        component:()=>import('@/views/Board/index')
      },
      {
        path:"board/country",
        name:"HomeBoardCountry",
        component:()=>import('@/views/Board/country'),
        meta:{title:"接待全国看板"}
      },
      {
        path:"board/region",
        name:"HomeBoardRegion",
        component:()=>import('@/views/Board/region'),
        meta:{title:"接待大区看板"}
      },
      {
        path:"board/area",
        name:"HomeBoardArea",
        component:()=>import('@/views/Board/area'),
        meta:{title:"接待小区看板"}
      },
      {
        path:"board/store",
        name:"HomeBoardStore",
        component:()=>import('@/views/Board/store'),
        meta:{title:"接待经销商看板"}
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
