import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible/flexible.js'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/index.scss'
import {Alert, Confirm, Toast} from 'wc-messagebox'
import Fragment from 'vue-fragment'


import 'wc-messagebox/style.css'
Vue.use(Fragment.Plugin)
Vue.use(Alert)
Vue.use(Confirm)
Vue.use(Toast)
Vue.config.productionTip = false

import Calendar from 'vue-mobile-calendar'
Vue.use(Calendar);

Vue.use(ElementUI);
import '@/permission.js'
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
