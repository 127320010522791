import router from './router'
import store from './store'
import { getToken } from '@/utils/auth'
import {  getData } from '@/api/index'
import { checkRole } from '@/utils/index'
import {getCookie, setCookie} from "@/utils/cookie.js";
import querystring from 'querystring'
const whiteList = ['/login'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
    document.title = to.meta.title

    const url = location.href.match(/\?(.*)$/);
    const search = url && querystring.parse(url[1]) || {};
    const authId = search.adminId;
    const hasToken = getToken();
    const authToken = getCookie('AUTH_TOKEN');

    const sameId = authId == getCookie('AUTH_ID');
    const sameToken = authToken == hasToken;

    if (authId && (!authToken || !sameId || !sameToken)) {
      await store.dispatch('authorization', authId);
      authId && setCookie('AUTH_ID', authId);
      next();
      return;
    }

    if (hasToken) {
        if (to.path === '/login') {
            const {data:authList}=await getData("/api/admin/auth");
            const  result=checkRole(authList);
            next("/"+result);
        }else{
            next()
        }

    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next();
        } else {
            next("/login");
        }
    }
})
