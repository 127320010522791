<template>
  <div id="home">
    <div class="dateDiv">
      <ul>
        <li
          @click="changeCurrent(item)"
          v-for="item in filterOpts"
          :key="item.key"
          :class="item.key === current_key ? 'active' : ''"
        >
          {{ item.label }}
        </li>
      </ul>
      <div class="datepicker">
        <div class="label">时间</div>
        <div class="input" @click="selectData">
          <span>{{ dateRange || "开始日期-结束日期" }}</span>
        </div>
      </div>
    </div>
    <div class="searchDiv" v-if="isSuper=='-1'">
      <div class="item">
        <span class="label">集团名称</span>
        <span class="select">
          <el-select v-model.number="query.crop_id" @change="changeCrop">
            <el-option
              v-for="item in optList.cropOpts"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option> </el-select
        ></span>
      </div>

      <div class="item">
        <span class="label">品牌名称</span>
        <span class="select"
          ><el-select v-model.number="query.brand_id">
            <el-option
              v-for="item in optList.brandOpts"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option> </el-select
        ></span>
      </div>
      <div class="item" v-if="isShowRegion">
        <span class="label">大区名称</span>
        <span class="select"
          ><el-select v-model.number="query.region_id" @change="changeRegion">
            <el-option
              v-for="item in optList.regionOpts"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option> </el-select
        ></span>
      </div>
      <div class="item" v-if="isShowArea">
        <span class="label">小区名称</span>
        <span class="select"
          ><el-select v-model.number="query.area_id" @change="changeArea">
            <el-option
              v-for="item in optList.areaOpts"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option> </el-select
        ></span>
      </div>
      <div class="item"  v-if="isShowStore">
        <span class="label">公司</span>
        <span class="select"
          ><el-select v-model.number="query.company_id">
            <el-option
              v-for="item in optList.companyOpts"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option> </el-select
        ></span>
      </div>
      <div class="item">
        {{content}}
        <span @click="searchData" class="btn search">查询</span>
        <span @click="resetQuery" class="btn reset">重置</span>
      </div>
    </div>
    <router-view ref="child"></router-view>
    <div class="userDiv" @click="jumpToUser"></div>
    <calendar mode="during" :show.sync="isShow" @change="changeDate" />
  </div>
</template>
<script>
import { getOptions } from "@/api/common";
import { getData } from "@/api";
import { getFormatDate } from "@/utils";
import Menu from "@/utils/menu.js";
import {getCookie} from "@/utils/cookie.js";
import {jump} from "@/utils/index"
function StringToNum(obj){
  for(let i in obj){
    if(i!='start_date'&&i!="end_date"){
    obj[i]=obj[i]?Number(obj[i]):""
    }

  }
    return obj
}
export default {
  data() {

    return {
      isSuper:getCookie("isSuper"),
      optList: {},
      query: {},
      filterOpts: [
        { key: "month", label: "本月" },
        { key: "week", label: "本周" },
        { key: "today", label: "本日" },
      ],
      value: "",
      content:"",
      isShow: false,
      current_key: "month",
      isShowRegion: true,
      isShowArea: true,
      isShowStore: true,
    };
  },
  computed: {
    
  },
  watch: {
    $route: {
      handler() {
        if (Menu.RegionHidden.includes(this.$route.name)) {
          this.isShowRegion = false;
        } else {
          this.isShowRegion = true;
        }
         if (Menu.AreaHidden.includes(this.$route.name)) {
          this.isShowArea = false;
        } else {
          this.isShowArea = true;
        }
         if (Menu.storeHidden.includes(this.$route.name)) {
          this.isShowStore = false;
        } else {
          this.isShowStore = true;
        }
    
        this.query={...this.$store.state.query,...StringToNum(this.$route.query)};
        this.$store.commit("SET_QUERY", this.query);
      },

      immediate: true,
    },
  },
  async created() {   
    this.init();
  },
  computed: {
 
    dateRange() {
      return this.query.start_date && this.query.end_date
        ? this.query.start_date + "至" + this.query.end_date
        : "";
    },
  },
  methods: {
    async init() {
      const { data: cropOpts } = await getOptions({ table: "crop" });
      const { data: brandOpts } = await getOptions({ table: "brand" });
      const { data: regionOpts } = await getOptions({ table: "region" });
      const { data: areaOpts } = await getOptions({ table: "area" });
      const { data: companyOpts } = await getOptions({ table: "company" });
      this.optList = {
        cropOpts,
        brandOpts,
        regionOpts,
        areaOpts,
        companyOpts,
      };
    },
    async changeCrop(item) {
      this.query = {
        ...this.query,
        region_id: "",
        area_id: "",
        company_id: "",
        brand_id: "",
      };
      const { data: brandOpts = [] } = await getOptions({
        table: "brand",
        crop_id: item,
      });
      const { data: regionOpts = [] } = await getOptions({
        table: "region",
        crop_id: item,
      });
      const { data: areaOpts = [] } = await getOptions({
        table: "area",
        crop_id: item,
      });
      const { data: companyOpts = [] } = await getOptions({
        table: "company",
        crop_id: item,
      });
      this.optList = {
        ...this.optList,
        brandOpts,
        regionOpts,
        areaOpts,
        companyOpts,
      };
    },
    async changeRegion(item) {
      const { data: areaOpts = [] } = await getOptions({
        table: "area",
        region_id: item,
      });
      const { data: companyOpts = [] } = await getOptions({
        table: "company",
        region_id: item,
      });
      this.optList = {
        ...this.optList,
        areaOpts,
        companyOpts,
      };
    },
    async changeArea(item) {
      const { data: companyOpts = [] } = await getOptions({
        table: "company",
        area_id: item,
      });
      this.optList = {
        ...this.optList,
        companyOpts,
      };
    },
    changeCurrent(item) {
      this.current_key = item.key;
      const { start_date, end_date } = getFormatDate(this.current_key);
      this.query = {
        ...this.query,
        start_date,
        end_date,
      };
      this.searchData();
    },
    selectData() {
      this.isShow = true;
    },
    jumpToUser(){
     jump("/user",this)
    },
    changeDate(item) {
      if (item.length === 2) {
        this.query = {
          ...this.query,
          start_date: item[0].format("YYYY-MM-DD"),
          end_date: item[1].format("YYYY-MM-DD"),
        };
        this.isShow = false;
        this.current_key = "";
        this.searchData();
      }
    },
    resetQuery() {
      this.query = {};
      this.optList = {};
      this.init();
    },
    searchData() {
      this.$store.commit("SET_QUERY", this.query);
      this.$refs.child.getDataBySearch();
    },
  },
};
</script>
<style scoped lang="scss">
#home {
  padding: 0.185185rem /* 10/54 */ 0.203704rem /* 11/54 */ 1.481481rem /* 80/54 *//* 60/54 */ .203704rem /* 11/54 */;

  .dateDiv {
    display: flex;
    align-items: center;
    ul {
      width: 5.648148rem /* 305/54 */;
      height: 0.592593rem /* 32/54 */;
      display: flex;
      color: #fff;
      justify-content: space-between;
      li {
        width: 1.685185rem /* 91/54 */;
        height: 0.592593rem /* 32/54 */;
        background: url("../assets/date.png");
        line-height: 0.592593rem /* 32/54 */;
        text-align: center;
        &.active {
          background: url("../assets/dateSelect.png");
        }
      }
    }
    .datepicker {
      flex: 1;
      display: flex;
      align-items: center;

      justify-content: flex-end;
      .label {
        color: #00b7ee;
        margin-right: 0.092593rem /* 5/54 */;
      }
      .input {
        width: 3.055556rem /* 165/54 */;
        height: 0.444444rem /* 24/54 */;
        border: 1px solid #0e8bff;
        line-height: 0.444444rem /* 24/54 */;
        background: transparent;
        text-align: center;
        color: #fff;
        font-size: 0.240741rem /* 13/54 */;
      }
    }
  }
  .searchDiv {
    margin: 0.37037rem /* 20/54 */ auto 0.259259rem /* 14/54 */;
    display: flex;
    flex-wrap: wrap;
    // height: 1.481481rem /* 80/54 */;
    .item {
      width: 3.092593rem /* 167/54 */;
      height: 0.444444rem /* 24/54 */;
      margin-bottom: 0.296296rem /* 16/54 */;
      color: #00b7ee;
      display: flex;
      align-items: center;

      margin-right: 0.092593rem /* 5/54 */;
      .label {
        width: 1.259259rem /* 68/54 */;
        height: 0.5rem /* 27/54 */;
        line-height: 0.5rem;
        white-space: nowrap;
        font-size: 0.277778rem /* 15/54 */;
      }
      .select {
        flex: 1;

        height: 0.5rem /* 27/54 */;
        ::v-deep .el-input__inner {
          background: transparent;
          height: 0.5rem /* 27/54 */;
          line-height: 0.5rem /* 27/54 */;
          color: #fff;
          font-size: 0.240741rem /* 13/54 */;
          padding: 0 0.185185rem /* 10/54 */;
        }
        ::v-deep .el-input__icon {
          height: 0.5rem /* 27/54 */;
          line-height: 0.5rem /* 27/54 */;
        }
      }
      .btn {
        width: 0.888889rem /* 48/54 */;
        height: 0.462963rem /* 25/54 */;
        text-align: center;
        line-height: 0.462963rem /* 25/54 */;
        border: 1px solid #00b7ee;
        color: #fff;
        margin: 0px 0.055556rem /* 3/54 */;
        border-radius: 5px;
        &.search {
          background: #0e8bff;
        }
      }
    }
  }
  .userDiv{
    background-color: #000b67;
    position: fixed;
    left:0px;
    bottom:0px;
    width: 100%;
    height:.925926rem /* 50/54 */;
    background-image: url(../assets/user2.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: .462963rem /* 25/54 */;
  }
}
</style>
