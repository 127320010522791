import moment from 'moment'
import wx from 'weixin-js-sdk'
import querystring from 'querystring'
import {getCookie} from "@/utils/cookie.js";

const brand_ids = (getCookie("brand_ids") || '').split(',');
const DTS_ID = 27;
const isDTS = brand_ids.indexOf(String(DTS_ID)) >= 0;

const threeCropList = [16];
const DAZHONG_ID = 7;
const isBrandDZ = brand_ids.indexOf(String(DAZHONG_ID)) >= 0;
const isCropDZ = threeCropList.find(o => o == getCookie('cropId'));
const showThree = isCropDZ && isBrandDZ;

const cropId = getCookie("cropId");
const isVolvo = cropId == 12;



export function checkRole(roles) {
    let country = roles.find(item => {
        return item.name === 'nationStateIndex' || item.name === 'battleNation' || item.name === 'nationIndex'
    })
    if (country) {
        return 'country'
    }
    let region = roles.find(item => {
        return item.name === 'regionStateIndex' || item.name === 'battleRegion' || item.name === 'regionIndex'
    })
    if (region) {
        return 'region'
    }
    let area = roles.find(item => {
        return item.name === 'areaStateIndex' || item.name === 'battleArea' || item.name === 'areaIndex'
    })
    if (area) {
        return 'area'
    }
    let company = roles.find(item => {
        return item.name === 'storeStateIndex' || item.name === 'battleStore' || item.name === 'storeIndex'
    })
    if (company) {
        return 'company'
    }
    return '';
}
export function getFormatDate(item) {
    let start_date;
    let end_date;
    if (item == 'today') {
        start_date = moment(moment().startOf('day').valueOf()).format('YYYY-MM-DD');
        end_date = moment(moment().valueOf()).format('YYYY-MM-DD');
    } else if (item == 'week') {
        start_date = moment().weekday(1).format('YYYY-MM-DD');
        end_date = moment(moment().valueOf()).format('YYYY-MM-DD');

    } else if (item == 'yesterday') {
        start_date = moment().add(-1, 'day').format('YYYY-MM-DD');
        end_date = moment().add(-1, 'day').format('YYYY-MM-DD');

    } else if (item == 'lastMonth') {
        start_date = moment().add(-1, 'month').startOf('month').format('YYYY-MM-DD');
        end_date = moment().add(-1, 'month').endOf('month').format('YYYY-MM-DD');

    } else {
        start_date = moment(moment().month(moment().month()).startOf('month').valueOf()).format('YYYY-MM-DD');
        end_date = moment(moment().valueOf()).format('YYYY-MM-DD');
    }
    return {
        start_date,
        end_date
    }
}
export function jump(url, that,query={}) {
    var ua = window.navigator.userAgent.toLowerCase();


    if (ua.match(/MicroMessenger/i) == "micromessenger") {
   
        //微信环境下
        wx.miniProgram.getEnv(function (res) {
            if (res.miniprogram) {
                // 小程序环境下逻辑
                let querstring=querystring.stringify(query);
                const { origin, pathname } = location;
                let newurl = origin + pathname + "#" + url+"?"+querstring

                wx.miniProgram.navigateTo({ url: '../webview/webview?weburl=' + encodeURIComponent(newurl) });

            } else {
                //非小程序环境下逻辑
                that.$router.push({ path: url,query })

            }
        });
    } else {
    
   
        that.$router.push({ path: url,query })
    }
}
export function getLightColor(v) {
    if (v < 60 || v === '-') {
        return {
            ft: 'red',
            bg: '#5b263d',
        };
    }
    if (v >= 80) {
        return {
            ft: 'green',
            bg: '#0b701f',
        };
    }
    return {
        ft: 'yellow',
        bg: '#a79500',
    };
}

export function formatTotalScore(list) {
  return (list || []).map(o => ({
    id: o.id,
    name: o.name,
    dataList: [
      [o.total],
      [[o.system, [o.usage, o.usage_numerator, o.usage_denominator], [o.valid, o.valid_numerator, o.valid_denominator]]],
      delEmpty([o.hall_score, o.hall1, o.hall2, o.hall3, o.hall4,
        o.hall5, o.hall6, o.hall7, o.hall8])
    ]
  }));
}

export function formatVolvoList(list, titles) {
  //const a = ['接待得分','接待得分','接待得分'];
  const caption = ['', '展厅接待得分', '系统使用得分', '接待质量得分'].map((o, i) => {
    const subTit = ['', '', '', ['总分', ...titles]];
    return {
      title: o,
      subs: subTit[i]
    }
  });

  const keys = [...new Array(titles.length)].map((o,i) => ('hall' + (i+1)));
  const scoreList = (list || []).map(o => ({
    id: o.id,
    name: o.name,
    dataList: [
      [o.total],
      [[o.system, [o.usage, o.usage_numerator, o.usage_denominator], [o.valid, o.valid_numerator, o.valid_denominator]]],
      delEmpty([o.hall_score, ...keys.map(k => (o[k] || 0))])
    ]
  }));

  return {caption, scoreList};
}

export function formatVolvoListDTS(list, titles) {
  const caption = ['', '系统使用得分', '接待质量得分'].map((o, i) => {
    const subTit = ['', '', ['展厅接待质量得分', ...titles]];
    return {
      title: o,
      subs: subTit[i]
    }
  });

  const keys = [...new Array(titles.length)].map((o,i) => ('hall' + (i+1)));
  const scoreList = (list || []).map(o => ({
    id: o.id,
    name: o.name,
    dataList: [
      [[o.system, [o.usage, o.usage_numerator, o.usage_denominator], [o.valid, o.valid_numerator, o.valid_denominator]]],
      delEmpty([o.reception_score, ...keys.map(k => (o[k] || 0))])
    ]
  }));

  return {caption, scoreList};
}

export function delEmpty(list) {
  return list.filter(o => (o !== undefined && o !== null));
}


export default {
  DTS_ID,
  isDTS,
  DAZHONG_ID,
  showThree,
  jump,
  checkRole,
  getFormatDate,
  getLightColor,
  formatVolvoList,
  formatVolvoListDTS,
  delEmpty,
  isVolvo,
}
 
