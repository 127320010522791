<template>
  <div id="home" class="dashboard">
    <div class="box-search">
      <div class="item">
        <el-select v-model="curKey" placeholder="日期" @change="changeCurrent">
            <el-option
              v-for="item in filterOpts"
              :key="item.key"
              :label="item.label"
              :value="item.key"
            ></el-option>
          </el-select>
      </div>
      <div class="item custom" v-if="dateSel">
        <div class="date">
        <el-date-picker
          @change="changePicker"
          v-model="query.start_date"
          type="date"
          prefix-icon=" "
          value-format="yyyy-MM-dd"
          placeholder="开始日期">
        </el-date-picker>
        <span>至</span>
        <el-date-picker
          @change="changePicker"
          v-model="query.end_date"
          type="date"
          prefix-icon=" "
          value-format="yyyy-MM-dd"
          placeholder="结束日期">
        </el-date-picker>
        </div>
      </div>

      <fragment v-if="isVendor">
        <div class="item" v-if="(optList.brandOpts || []).length>1">
          <el-select v-model.number="query.brand_id"
            @change="onBrand"
            :clearable="(optList.brandOpts || []).length>1"
            :placeholder="brandName">
              <el-option
                v-for="item in optList.brandOpts"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
          </el-select>
        </div>
        <div class="item" v-if="isShowComType">
          <el-select v-model.number="query.company_type_id"
            :clearable="(optList.companyTypeOtps || []).length>1"
            placeholder="门店类型" @change="onCompanyType">
            <el-option
              v-for="item in optList.companyTypeOtps"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select >
        </div>
      </fragment>

      <div class="item">
        <el-select v-model="query.in_num"
          :clearable="(optList.entryStateOpts || []).length>1"
          placeholder="进店情况" @change="handleChange">
            <el-option
              v-for="item in optList.entryStateOpts"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
      </div>
      <div class="item">
        <el-select v-model.number="query.is_this_test_drive"
          :clearable="(optList.driveOpts || []).length>1"
          placeholder="试乘试驾" @change="handleChange">
            <el-option
              v-for="item in optList.driveOpts"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
      </div>
    <fragment class="box-org" v-if="isSuper">
      <div class="item">
        <el-select v-model.number="query.crop_id"
          :clearable="(optList.cropOpts || []).length>1"
          placeholder="集团名称" @change="changeCrop">
            <el-option
              v-for="item in optList.cropOpts"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
      </div>

      <div class="item">
        <el-select v-model.number="query.brand_id"
          :clearable="(optList.brandOpts || []).length>1"
          :placeholder="brandName" @change="changeBrand">
            <el-option
              v-for="item in optList.brandOpts"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
        </el-select>
      </div>
      <div class="item" v-if="isShowRegion">
        <el-select v-model.number="query.region_id"
          :clearable="(optList.regionOpts || []).length>1"
          placeholder="大区名称" @change="changeRegion">
            <el-option
              v-for="item in optList.regionOpts"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
        </el-select>
      </div>
      <div class="item" v-if="isShowArea">
        <el-select v-model.number="query.area_id"
          :clearable="(optList.areaOpts || []).length>1"
          placeholder="小区名称" @change="changeArea">
          <el-option
            v-for="item in optList.areaOpts"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select >
      </div>
      <div class="item" v-if="isShowComType">
        <el-select v-model.number="query.company_type_id"
          :clearable="(optList.companyTypeOtps || []).length>1"
          placeholder="门店类型" @change="changeCompanyType">
          <el-option
            v-for="item in optList.companyTypeOtps"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select >
      </div>
      <div class="item"  v-if="isShowStore">
        <el-select v-model.number="query.company_id" filterable
          :clearable="(optList.companyOpts || []).length>1"
          placeholder="公司名称">
            <el-option
              v-for="item in optList.companyOpts"
              :key="item.id"
              :label="String(item.code).toUpperCase() + item.name"
              :value="item.id"
            ></el-option>
        </el-select>
      </div>
      <div class="box-btn">
        <span @click="searchData" class="search">查询</span>
        <span @click="resetQuery" class="reset">重置</span>
      </div>
    </fragment>
    <div v-else class="item" style="height: 0;"></div>
    </div>
    <router-view ref="child" :key="this.$route.path"/>
    <div class="userDiv" @click="jumpToUser"></div>
    <calendar mode="during" :show.sync="isShow" @change="changeDate" />
  </div>
</template>
<script>
import { getOptions } from "@/api/common";
import { getData } from "@/api";
import { getFormatDate } from "@/utils";
import Menu from "@/utils/menu.js";
import {getCookie} from "@/utils/cookie.js";
import {jump} from "@/utils/index"
import util from "@/utils/index";

function StringToNum(obj){
  for(let i in obj){
    if(i!='start_date'&&i!="end_date"){
      obj[i] = (isNaN(Number(obj[i])) || !obj[i]) ? obj[i] : Number(obj[i]);
    }

  }
    return obj
}

export default {
  data() {

    return {
      isSuper: false,
      isVendor: false,
      optList: {},
      query: {},
      filterOpts: [
        { key: "month", label: "本月" },
        { key: "week", label: "本周" },
        { key: "yesterday", label: "本日" },
        { key: "lastMonth", label: "上月" },
        { key: "custom", label: "自定义" },
      ],
      entryStateOpts: [
        { key: 0, value: '首次进店', label: '首次进店'},
        { key: 1, value: '再次进店', label: '再次进店'},
      ],
      value: "",
      content:"",
      isShow: false,
      curKey: "yesterday",
      isShowRegion: true,
      isShowArea: true,
      isShowComType: true,
      isShowStore: true,
      dateSel: false,
      brandName: '品牌名称',
    };
  },
  computed: {
    
  },
  watch: {
    $route: {
      handler() {
        if (Menu.RegionHidden.includes(this.$route.name)) {
          this.isShowRegion = false;
        } else {
          this.isShowRegion = true;
        }
         if (Menu.AreaHidden.includes(this.$route.name)) {
          this.isShowArea = false;
        } else {
          this.isShowArea = true;
        }
         if (Menu.storeHidden.includes(this.$route.name)) {
          this.isShowStore = false;
        } else {
          this.isShowStore = true;
        }
    
        const {region_id, area_id, company_id, ...rest} = this.$store.state.queryDB || {};
        const brandId = Number(getCookie("brandId")) || '';
        const cropId = Number(getCookie("cropId")) || '';
        this.query={
          ...rest,
          crop_id: cropId,
          brand_id: brandId,
          in_num: '首次进店',
          ...StringToNum(this.$route.query)
        };
        this.setDefaultValue();
        this.$store.commit("SET_DB_QUERY", this.query);
      },

      immediate: true,
    },
  },
  async created() {
    const adminId = getCookie("adminId");
    this.isSuper = [-1].indexOf(Number(adminId)) >= 0;
    this.isVendor = [1].indexOf(Number(adminId)) >= 0;

    const {start_date: s, end_date: e} = this.query;
    if(!s && !e) {
      this.curKey = 'yesterday';
      const { start_date, end_date } = getFormatDate(this.curKey);
      this.query = {
        ...this.query,
        start_date,
        end_date,
      }
      this.$store.commit("SET_DB_QUERY", this.query);
    } else {
      this.curKey = 'custom';
      this.dateSel = true;
      this.query = {
        ...this.query,
        start_date: s,
        end_date: e,
      }
    }


    this.init();
  },
  methods: {
    async onBrand(v) {
      await this.changeBrand(v);
      this.searchData();
    },
    async onCompanyType(v) {
      await this.changeCompanyType(v);
      this.searchData();
    },
    setDefaultValue() {
      const {cropOpts, brandOpts,
        regionOpts, areaOpts, companyOpts} = this.optList;

      const q = setDefaultValue([
        {key: 'crop_id', arr: cropOpts},
        {key: 'brand_id', arr: brandOpts},
        {key: 'region_id', arr: regionOpts},
        {key: 'area_id', arr: areaOpts},
        {key: 'company_id', arr: companyOpts},
      ]);
      this.query = {...this.query, ...q};
    },
    async init() {
      const {crop_id, brand_id, region_id, area_id, dealer_group_id, company_type_id} = this.query;
      const { data: cropOpts } = await getOptions({ table: "crop" });
      const { data: brandOpts } = await getOptions({ table: "brand", crop_id, dealer_group_id });
      const { data: regionOpts } = await getOptions({ table: "region", crop_id, dealer_group_id, brand_id });
      const { data: areaOpts } = await getOptions({ table: "area", crop_id, dealer_group_id, brand_id, region_id });
      const { data: companyTypeOtps} = await getData('/api/option/getCompanyTypeList', {crop_id, dealer_group_id, brand_id, region_id, area_id});

      const { data: companyOpts } = await getOptions({ table: "company", crop_id, dealer_group_id, brand_id, region_id, area_id, company_type_id  });
      const { data: entryStateOpts} = await getData('/api/option/in_num', {business: '展厅接待'});
      const { data: driveOpts} = await getData('/api/option/is_this_test_drive');
      this.optList = {
        cropOpts,
        brandOpts,
        regionOpts,
        areaOpts,
        companyOpts,
        entryStateOpts,
        driveOpts,
        companyTypeOtps,
      };

      this.setDefaultValue();
    },
    changePicker() {
      this.searchData();
    },
    async changeCrop(item) {
      this.query = {
        ...this.query,
        region_id: "",
        area_id: "",
        company_id: "",
        brand_id: "",
      };
      const { data: brandOpts = [] } = await getOptions({
        table: "brand",
        crop_id: item,
      });
      const { data: regionOpts = [] } = await getOptions({
        table: "region",
        crop_id: item,
      });
      const { data: areaOpts = [] } = await getOptions({
        table: "area",
        crop_id: item,
      });
      const { data: companyOpts = [] } = await getOptions({
        table: "company",
        crop_id: item,
      });
      const { data: companyTypeOtps = []} = await getData('/api/option/getCompanyTypeList', {
        crop_id: item,
      });
      this.optList = {
        ...this.optList,
        brandOpts,
        regionOpts,
        areaOpts,
        companyOpts,
        companyTypeOtps,
      };
    },
    async changeBrand(item) {
      this.query = {
        ...this.query,
        company_id: "",
        company_type_id: "",
      };
      const { data: companyOpts = [] } = await getOptions({
        table: "company",
        crop_id: this.query.crop_id,
        dealer_group_id: this.query.dealer_group_id,
        brand_id: item,
      });
      const { data: companyTypeOtps = []} = await getData('/api/option/getCompanyTypeList', {
        crop_id: this.query.crop_id,
        dealer_group_id: this.query.dealer_group_id,
        brand_id: item,
      });
      this.optList = {
        ...this.optList,
        companyOpts,
        companyTypeOtps,
      };

    },
    
    async changeRegion(item) {
      this.query = {
        ...this.query,
        area_id: "",
        company_id: "",
        company_type_id: "",
      };
      
      const { data: areaOpts = [] } = await getOptions({
        table: "area",
        region_id: item,
      });
      const { data: companyOpts = [] } = await getOptions({
        table: "company",
        region_id: item,
      });
      const { data: companyTypeOtps = []} = await getData('/api/option/getCompanyTypeList', {
        crop_id: this.query.crop_id,
        dealer_group_id: this.query.dealer_group_id,
        brand_id: this.query.brand_id,
        region_id: item,
      });
      
      this.optList = {
        ...this.optList,
        areaOpts,
        companyOpts,
        companyTypeOtps,
      };
    },
    async changeArea(item) {
      this.query = {
        ...this.query,
        company_id: "",
        company_type_id: "",
      };
      
      const { data: companyOpts = [] } = await getOptions({
        table: "company",
        area_id: item,
      });
      const { data: companyTypeOtps = []} = await getData('/api/option/getCompanyTypeList', {
        crop_id: this.query.crop_id,
        dealer_group_id: this.query.dealer_group_id,
        brand_id: this.query.brand_id,
        region_id: this.query.region_id,
        area_id: item,
      });
      
      this.optList = {
        ...this.optList,
        companyOpts,
        companyTypeOtps,
      };
    },
    async changeCompanyType(item) {
      if (!this.isShowStore) {
        return;
      }
      this.query = {
        ...this.query,
        company_id: "",
      };
      const { data: companyOpts = [] } = await getOptions({
        table: "company",
        crop_id: this.query.crop_id,
        dealer_group_id: this.query.dealer_group_id,
        brand_id: this.query.brand_id,
        region_id: this.query.region_id,
        area_id: this.query.area_id,
        company_type_id: item,
      });
      this.optList = {
        ...this.optList,
        companyOpts,
      };
    },
    
    changeCurrent(key) {
      if (key == 'custom') {
        this.dateSel = true;
        this.query = {
          ...this.query,
          start_date: '',
          end_date: '',
        };
        return;
      }
      this.dateSel = false;
      const { start_date, end_date } = getFormatDate(key);
      this.query = {
        ...this.query,
        start_date,
        end_date,
      };
      this.searchData();
    },
    selectData() {
      this.isShow = true;
    },
    jumpToUser(){
     jump("/user",this)
    },
    changeDate(item) {
      if (item.length === 2) {
        this.query = {
          ...this.query,
          start_date: item[0].format("YYYY-MM-DD"),
          end_date: item[1].format("YYYY-MM-DD"),
        };
        this.isShow = false;
        this.curKey = "";
        this.searchData();
      }
    },
    resetQuery() {
      this.query = {};
      this.optList = {};
      this.init();
    },
    handleChange(v) {
      this.searchData();
    },
    searchData() {
      this.$store.commit("SET_DB_QUERY", this.query);
      this.$refs.child.getDataBySearch();
    },
  },
};

function setDefaultValue(list) {
  console.log(list);
  return list.reduce((s,o) => {
    if ((o.arr || []).length == 1) {
      return {
        ...s,
        [o.key]: o.arr[0].id
      }
    } else {
      return s;
    }
  }, {});
}
</script>
<style lang="scss">
.el-select-dropdown {
  .el-select-dropdown__item {
    max-width: 9rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.box-jumpto-store {
  margin-top: 0.32rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  font-size: 0.37333rem;
  .btn {
    background: #fff;
    color: #000;
    border-radius: 6px;
    display: inline-block;
    border: 1px solid #DCDFE6;
    line-height: 0.8rem;
    height: 0.8rem;
    padding: 0 0.2rem;
  }
  .el-select {
    flex: 1;
    margin: 0 0.26666rem;
    .el-input__inner, .el-input__icon {
      line-height: 0.8rem;
      height: 0.8rem;
    }
  }
}

.box-search, .box-common {
  .item {
    margin-bottom: 0.26666rem;
    overflow: hidden;
    background: #E5E8EE;
    border-radius: 2px;
  }

  .el-select {
    width: 100%;
  }

  .el-input__inner {
    text-align: center;
    background-color: transparent;
    border: 0;
    padding: 0 0.18666rem;
    color: #4F5154;
    height: 1.06666rem;
    line-height: 1.06666rem;
    font-size: 0.37333rem;
    font-weight: 500;
    &::-webkit-input-placeholder{
      color: #4F5154; 
      font-size: 0.37333rem;
      font-weight: 500;
    }
  }

  .date input.el-input__inner {
  }
  .el-select .el-input .el-select__caret {
      color: #929498;
  }
}
  .box-search {
      color: #4F5154;
      font-weight: 500;
      display: flex;
      font-size: 0.37333rem;
      flex-wrap:wrap;
      justify-content: space-between;

      .item {
        width: 32%;
        margin-bottom: 0.26666rem;
        overflow: hidden;
        background: #E5E8EE;
        border-radius: 2px;
      }

      .custom {
        min-width: 66%;
        .date {
          padding-left: 0.8rem;
          display: flex;
          align-items: center;
          background: url(~@/assets/i_date.png) no-repeat 0.1rem 0.26666rem;
          background-size: 0.53333rem 0.53333rem;
          .el-input__inner {
            padding: 0;
          }
        }
      }

      .box-btn {
        width: 32%;
        display: flex;
        span {
          font-size: 1em;
          line-height: 1.06666rem;
          flex: 1;
          text-align: center;
          font-weight: 500;
          color: #969799;
          &.search {
            color: #377EEA;
          }
        }
      }
  }

  .box-common {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .item {
      flex: 1;
      min-width: 32%;
      max-width: 32%;
      margin-bottom: 0.26666rem;
      overflow: hidden;
      margin-right: 2%;
      &:first-child {
      }
      &.custom {
        margin-right: 0;
        min-width: 66%;
        .date {
          padding-left: 0.8rem;
          display: flex;
          align-items: center;
          background: url(~@/assets/i_date.png) no-repeat 0.1rem 0.26666rem;
          background-size: 0.53333rem 0.53333rem;
          .el-input__inner {
            padding: 0;
          }
        }
      }
    }
  }

  .box-org {
    flex-wrap:wrap;
    justify-content: space-between;
    >div {
      width: 32%;
      margin-bottom: 0.26666rem;
      overflow: hidden;
    }
  }
  .box-date, .box-org {
    color: #4F5154;
    font-weight: 500;
    display: flex;
    font-size: 0.37333rem;

    >div {
      width: 32%;
      margin-bottom: 0.26666rem;
      overflow: hidden;
    }

    .item {
      background: #E5E8EE;
      border-radius: 2px;
    }

    .box-btn {
      display: flex;
      span {
        font-size: 1em;
        line-height: 1.06666rem;
        flex: 1;
        text-align: right;
        font-weight: 500;
        color: #969799;
        &.search {
          color: #377EEA;
        }
      }
    }
  }

.dashboard {
  min-height: 100vh;
  padding: 0.4rem;
  padding-bottom: 1.5rem;
  background: linear-gradient(180deg, #E6EEF9 2%, #FFFFFF 94%);
  background-size: 100% 5.33333rem;
  background-repeat: no-repeat;
  background-color: #FFFFFF;
  max-width: 750px;
  margin: auto;

  .title {
    font-weight: 500;
    color: #000000;
    line-height: 0.50666rem;
    font-size: 0.42666rem;
    margin-bottom: 0.26666rem;
  }


  .line {
    height: 1px;
    background: #C3D5E4;
    margin: 0.53333rem 0;
  }

  .box-total {
    height: 2.66666rem;
    background: #0070C0;
    border-radius: 2px;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 0.26666rem;

    p {
      padding: 0;
      margin: 0;
      text-align: center;
      flex: 1;
    }

    span, strong, i {
      display: block;
      line-height: 1.5;
      font-style: normal;
    }

    strong {
      font-size: 2em;
    }
    .a {
      font-size: 3em;
      line-height: 1.1;
    }
    .a-p {
      font-size: 1.2em;
      opacity: 1;
    }

    .flag {
      max-width: 0.5rem;
      font-size: 0.53333rem;
      font-weight: bold;
    }

    .i-question {
      display: inline-block;
      width: 0.4rem;
      height: 0.4rem;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-image: url(~@/assets/i_question.png);
      vertical-align: top;
    }
  }

  .box-core {
    display: flex;
    flex-wrap:wrap;
    justify-content: space-between;
    .main {
      width: 4.4rem;
      height: 2.4rem;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin-top: 0.4rem;
      padding-left: 0.32rem;
      padding-top: 0.95rem;
      font-weight: 400;

      &:nth-child(1) {
        margin-top: 0;
        background-image: url(~@/assets/bg_s1.png);
      }
      &:nth-child(2) {
        margin-top: 0;
        background-image: url(~@/assets/bg_s2.png);
      }
      &:nth-child(3) {
        background-image: url(~@/assets/bg_s3.png);
      }
      &:nth-child(4) {
        background-image: url(~@/assets/bg_s4.png);
      }

      .label {
        opacity: 0.64;
      }
      .value {
        strong {
          font-size: 0.53333rem;
        }
      }
    }
  }


.list-top {
  padding-top: 0.1rem;
  max-height: 7.1rem;
  overflow: auto;
  li {
    margin-top: 0.26666rem;
    display: flex;
    align-items: center;
    .box-bar {
      flex: 1;
      position: relative;
      white-space: nowrap;
      height: 0.21333rem;
      .bara {
        background: #C8C9CC;
      }
      .bar {
        background: #969799;
      }
      span {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        display: inline-block;
        border-radius: 0.21333rem;
      }
    }
    .score {
      margin-left: 0.18666rem;
      display: block;
      width: 0.53333rem;
    }
    .label {
      width: 2.66666rem;
      font-size: 0.26666rem;
      overflow:hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
      strong {
        color: #fff;
        width: 0.37333rem;
        height: 0.37333rem;
        line-height: 1.25;
        margin-right: 0.13333rem;
        background: #C8C9CC;
        display: inline-block;
        text-align: center;
      }
    }
  }
  li:first-child {
    .label {
      strong {
        background: #ED5A54;
      }
    }
    .bar {
      background: #ED5A54;
    }
  }
  li:nth-child(2) {
    .label {
      strong {
        background: #FAC15C;
      }
    }
    .bar {
      background: #FAC15C;
    }
  }
  li:nth-child(3) {
    .label {
      strong {
        background: #11C16F;
      }
    }
    .bar {
      background: #11C16F;
    }
  }
}


.pop-score {
  .el-dialog {
    width: 94%;

    .el-dialog__body {
      padding: 30px 10px;
    }
  }
}

.pop-formula {
  .el-dialog {
    width: 94%;

    .el-dialog__body {
      padding: 30px 10px;
    }
  }
}
.box-formula {
  font-size: 0.32rem;
  p {
    padding: 0.2rem 0;
    display: flex;
    align-items: center;

    span {
      padding: 2px;
    }

    strong {
      text-align: center;
      display: block;
      line-height: 1.5;
      font-weight: normal;
      &:first-child {
        border-bottom: 1px solid #000;
      }
    }
  }
}

}
.userDiv{
  background-color: #0070C0;
  position: fixed;
  left:0px;
  bottom:0px;
  width: 100%;
  height: 1.06666rem;
  background-image: url(../assets/icon_my.png);
  background-repeat: no-repeat;
  background-position: center 0.14666rem;
  background-size: 0.61333rem;
}
</style>
