import axios from 'axios'
import store from '@/store'
import { getToken } from '@/utils/auth'
import { DOMAIN } from '@/utils/app'

const apiUrl = [
  'https://sit.api.linkprofit.cn/index.php/',
  'https://api.linkprofit.cn/index.php/'
];

function apiHost() {
  const host = location.hostname;
  if (/^program\./.test(host)) {
    return apiUrl[1];
  }
  return apiUrl[0];
}

const service = axios.create({
   baseURL: apiHost(),
})

service.interceptors.request.use(
  config => {
    if (getToken()) {
      config.headers['token'] = getToken()
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)


service.interceptors.response.use(
  async (response) => {
    const res = response.data
    if (res.status !== 1) {

      if (res.status === 401) {
        await store.dispatch('resetToken');
        location.reload();
      }   
      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      return res
    }
    return response
  },
  error => {
  
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)

export default service
